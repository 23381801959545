<template>
    <v-card
        min-width="650"
        class="pa-2"
        elevation="0"
    >

        <v-card
            flat
            color="transparent">
            <v-card-title>GOLD SETTING</v-card-title>
            <v-card-subtitle>(Origin: <strong>{{this.oldGold.ToReadableAlphabetV2()}}</strong> => Changed: <strong>{{kpiCumulatedGoldWon}})</strong></v-card-subtitle>
        </v-card>
        <v-card
            elevation="0"
            outlined
        >
            <v-card-subtitle><strong>Increase global Gold (%)</strong></v-card-subtitle>
            <v-text-field
                dense
                class="mx-5"
                v-model="percentageGoldBonus"
                @input="onPercentGoldInput"
                type="number"
                :min="0"
            />

        </v-card>

        <SettingGoldBetweenHeroesAndSupports
            :defaultInputSet="defaultInputSet"
            :totalGold="this.payload.kpiCumulatedGoldWon"
            @onUpdateSettingGoldBetweenHeroesAndSupports="onUpdateSettingGoldBetweenHeroesAndSupports"
        />

        <SettingGoldBetweenHeroLevelsAndSkills
            :defaultInputSet="defaultInputSet"
            :goldAvailableForHeroes="this.payload.kpiGoldAvailableForHero"
            @onUpdateSettingGoldBetweenHeroLevelsAndSkills="onUpdateSettingGoldBetweenHeroLevelsAndSkills"
        />

        <SettingGoldBetweenSupportLevelsAndSkills
            :defaultInputSet="defaultInputSet"
            :goldAvailableForSupports="this.payload.kpiGoldAvailableForSupport"
            @onUpdateSettingGoldBetweenSupportLevelsAndSkills="onUpdateSettingGoldBetweenSupportLevelsAndSkills"
        />

        <SettingGoldMiniQuestAndDailyQuest
            :defaultInputSet="defaultInputSet"
            @onUpdateSettingGoldMiniAndDailyQuest="onUpdateSettingGoldMiniAndDailyQuest"
        />

        <SettingGoldMarketPlace
            :defaultInputSet="defaultInputSet"
            @onUpdateSettingGoldMarketPlace="onUpdateSettingGoldMarketPlace"
        />

        <SettingHeroSupportLevelHistory
            :defaultInputSet="defaultInputSet"
            @onUpdateSettingHeroSupportLevelHistory="onUpdateSettingHeroSupportLevelHistory"
        />
    </v-card>
</template>

<script>
import SMPNum from "@/SMPNum";
import SettingGoldBetweenHeroesAndSupports
    from "@/kpi-editor/modules/components/components/SettingGoldBetweenHeroesAndSupports.vue";
import SettingGoldBetweenHeroLevelsAndSkills
    from "@/kpi-editor/modules/components/components/SettingGoldBetweenHeroLevelsAndSkills.vue";
import SettingGoldBetweenSupportLevelsAndSkills
    from "@/kpi-editor/modules/components/components/SettingGoldBetweenSupportLevelsAndSkills.vue";
import SettingGoldMiniQuestAndDailyQuest
    from "@/kpi-editor/modules/components/components/SettingGoldMiniQuestAndDailyQuest.vue";
import SettingGoldMarketPlace
    from "@/kpi-editor/modules/components/components/SettingGoldMarketPlace.vue";
import SettingHeroSupportLevelHistory
    from "@/kpi-editor/modules/components/components/SettingHeroSupportLevelHistory.vue";

export default {
    name: "PanelGoldConfig",
    components: {
        SettingGoldBetweenSupportLevelsAndSkills,
        SettingGoldBetweenHeroLevelsAndSkills,
        SettingGoldBetweenHeroesAndSupports,
        SettingGoldMiniQuestAndDailyQuest,
        SettingGoldMarketPlace,
        SettingHeroSupportLevelHistory
    },
    props:["payload", "defaultInputSet"],
    mounted() {
        console.log("ON MOUNTED")
    },
    created() {
        this.percentageGoldBonus = this.defaultInputSet.percentageGoldBonus;
        this.settingPercentageBetweenHeroLevelAndSkills.percentageAllocatedToHeroSkills = this.defaultInputSet.ratioAllocatedToHeroSkill;
        this.settingPercentageBetweenHeroesAndSupports.percentageAllocatedToSupports = this.defaultInputSet.ratioAllocatedToSupport;
        this.settingPercentageBetweenSupportLevelAndSkills.percentageAllocatedToSupportSkills = this.defaultInputSet.ratioSupportSkill;
        this.settingPercentageBetweenSupportLevelAndSkills.percentageAllocatedToSupportLevels = this.defaultInputSet.ratioAllocatedToSupportLevel;
        this.settingPercentageMiniAndDailyQuest.isIncludeDailyQuestGold = this.defaultInputSet.isIncludeDailyQuestGold;
        this.settingPercentageMiniAndDailyQuest.isIncludeMiniQuestGold = this.defaultInputSet.isIncludeMiniQuestGold;
        this.settingPercentageMiniAndDailyQuest.percentIncludeMiniQuestGold = this.defaultInputSet.percentIncludeMiniQuestGold;
        this.settingPercentageMiniAndDailyQuest.percentIncludeDailyQuestGold = this.defaultInputSet.percentIncludeDailyQuestGold;
        this.settingMarketPlace.isUseAllGoldForMarketPlace = this.defaultInputSet.isUseAllGoldForMarketPlace;
        this.settingHeroSupportHistory.balHeroLevelBelowBoss = this.defaultInputSet.balHeroLevelBelowBoss;
        this.settingHeroSupportHistory.balSupportLevelBelowBoss = this.defaultInputSet.balSupportLevelBelowBoss;

        this.oldGold = this.payload.kpiCumulatedGoldWon;
        console.log("ON CREATED")
    },
    data() {
        return {
            ratioAllocatedToHeroSkill: null,
            percentageGoldBonus: 0,
            settingPercentageBetweenHeroesAndSupports: {},
            settingPercentageBetweenHeroLevelAndSkills: {},
            settingPercentageBetweenSupportLevelAndSkills: {},
            settingPercentageMiniAndDailyQuest: {},
            settingMarketPlace: {},
            settingHeroSupportHistory: {},

            oldGold: SMPNum.fromNum(0)
        }
    },
    computed: {
        kpiCumulatedGoldWon () {
            const { kpiCumulatedGoldWon } = this.payload;
            return kpiCumulatedGoldWon.ToReadableAlphabetV2();
        },

    },
    methods: {
        onPercentGoldInput() {
            this.emitState();
        },

        onUpdateSettingGoldBetweenHeroesAndSupports(receivedPayload) {
            this.settingPercentageBetweenHeroesAndSupports = receivedPayload;

            this.emitState();
        },
        onUpdateSettingGoldBetweenHeroLevelsAndSkills(receivedPayload) {
            this.settingPercentageBetweenHeroLevelAndSkills = receivedPayload;

            this.emitState();
        },
        onUpdateSettingGoldBetweenSupportLevelsAndSkills(receivedPayload) {
            this.settingPercentageBetweenSupportLevelAndSkills = receivedPayload;

            this.emitState();
        },
        onUpdateSettingGoldMiniAndDailyQuest(receivedPayload) {
            this.settingPercentageMiniAndDailyQuest = receivedPayload;

            this.emitState();
        },
        onUpdateSettingGoldMarketPlace(receivedPayload) {
            this.settingMarketPlace = receivedPayload;

            this.emitState();
        },

        onUpdateSettingHeroSupportLevelHistory(receivedPayload) {
            this.settingHeroSupportHistory = receivedPayload;

            this.emitState();
        },

        emitState(){
            const outPayload = {
                percentageAllocatedToHeroLevels: this.percentageAllocatedToHeroLevels,
                percentageAllocatedToHeroes: this.percentageAllocatedToHeroes,

                ratioAllocatedToHeroSkill: this.settingPercentageBetweenHeroLevelAndSkills.percentageAllocatedToHeroSkills,
                ratioAllocatedToSupport: this.settingPercentageBetweenHeroesAndSupports.percentageAllocatedToSupports,

                ratioAllocatedToSupportSkill: this.settingPercentageBetweenSupportLevelAndSkills.percentageAllocatedToSupportSkills,
                ratioAllocatedToSupportLevel: this.settingPercentageBetweenSupportLevelAndSkills.percentageAllocatedToSupportLevels,

                percentageGoldBonus: this.percentageGoldBonus,

                isIncludeMiniQuestGold: this.settingPercentageMiniAndDailyQuest.isIncludeMiniQuestGold,
                isIncludeDailyQuestGold: this.settingPercentageMiniAndDailyQuest.isIncludeDailyQuestGold,

                percentIncludeMiniQuestGold: this.settingPercentageMiniAndDailyQuest.percentIncludeMiniQuestGold,
                percentIncludeDailyQuestGold: this.settingPercentageMiniAndDailyQuest.percentIncludeDailyQuestGold,

                isUseAllGoldForMarketPlace: this.settingMarketPlace.isUseAllGoldForMarketPlace,

                balHeroLevelBelowBoss: this.settingHeroSupportHistory.balHeroLevelBelowBoss,
                balSupportLevelBelowBoss: this.settingHeroSupportHistory.balSupportLevelBelowBoss
            };

            this.$emit("onUpdateSettingGold", outPayload);
        }
    }
}
</script>

<style scoped>

</style>