let SMPNum = require("../../SMPNum");
let SMPItemConfigHelper = require("./SMPItemConfigHelper");

class SMPItemImpactBatchHelperUtils {
	constructor(gamePlayService) {
		this.gamePlayService = gamePlayService;
		this.doneImplementImpact = [
			"damage_enemy",
			"inc-gold-drop",
			"inc_gold_enemy_drop",
			"inc_hp",
			"fill_hp",
			"inc-speed-support",
			"inc_dps",
			"poison-enemy",
			"freeze-enemy",
			"sheep-enemy",
			"inc_size_hero",
			"inc_pet_active_time",
			"auto_tap",
			"holding_tap",
			"inc_powerup_capacity"
		];

		this.itemBatchHelper = new SMPItemConfigHelper(gamePlayService);
	}

	isHasImplementImpact(impType) {
		return this.doneImplementImpact.includes(impType);
	}

	GetItemLevel(gameLevel, levelConfig) {
		let level = 1;
		let levelRange = gameLevel - levelConfig.startLevel;
		if (levelRange > 0 && levelRange >= levelConfig.levelRangePerLevel) {
			level += Math.floor(levelRange / levelConfig.levelRangePerLevel);
		}
		return level;
	}

	ApplyBatchImpactDirectValue(behaviour, gameLevel) {
		if (behaviour.gamePlayImpact.valueType === 'Percentage') {
			let bonus = new SMPNum(behaviour.gamePlayImpact.value);
			this.defineDirectValueBonusForImpact(behaviour, bonus, gameLevel);
		}
	}

	defineDirectValueBonusForImpact(behaviour, bonus, levelStart) {
		if (this.isHasImplementImpact(behaviour.type)) {
			let smpGetBase = new SMPNum(0);
			let bonusGoal = new SMPNum(0);
			let isDefineByDuration = this.isDefineByDurationToKillEnemy(behaviour.type);
			//var directUnit = "";
			if (this.isDefineByBossHp(behaviour.type)) {
				smpGetBase = this.gamePlayService.HPBoss(levelStart);
				bonusGoal = SMPNum.divSmpNum(SMPNum.multSmpNum(smpGetBase, bonus), new SMPNum(100));
				//directUnit = "dmg";
			} else if (this.isDefineByCoinDrop(behaviour.type)) {
				smpGetBase = this.gamePlayService.DropCoins(levelStart);
				bonusGoal = SMPNum.divSmpNum(SMPNum.multSmpNum(smpGetBase, bonus), new SMPNum(100));
				//directUnit = "golds";
			} else if (this.isDefineByBossDMG(behaviour.type)) {
				let bossDmgBalance = this.getBossDmgBalance(levelStart);
				bonusGoal = SMPNum.divSmpNum(SMPNum.multSmpNum(bossDmgBalance, bonus), new SMPNum(100));
				//smpGetBase = bossDmgBalance;
				//directUnit = "hp";
			} else if (this.isDefineByDmgBalanceToKillEnemy(behaviour.type) || this.isDefineByDMGAndConvertToPercent(behaviour.type)) {
				let dmgBalance = this.getDmgBalanceToKillEnemy(levelStart);
				bonusGoal = SMPNum.divSmpNum(SMPNum.multSmpNum(dmgBalance, bonus), new SMPNum(100));
				//smpGetBase = dmgBalance;
				//if (this.isUseDPSValueType(behaviour.type))
				//{
				//	directUnit = "dps";
				//}
				//else
				//{
				//	directUnit = "dmg";
				//}
			} else if (isDefineByDuration) {
				let durationBalance = this.getDurationBalanceToKillEnemy();
				bonusGoal = SMPNum.divSmpNum(SMPNum.multSmpNum(new SMPNum(durationBalance), bonus), new SMPNum(100));
				//smpGetBase = new SMPNum(durationBalance);
				//directUnit = "seconds";
			}

			//round for bonus
			//bonusGoal.RoundIfLessThan1K();

			if (this.isDefineByDMGAndConvertToPercent(behaviour.type)) {
				let bossHp = this.gamePlayService.HPBoss(levelStart);
				let reduceResult = SMPNum.divSmpNum(bonusGoal, bossHp);
				reduceResult = SMPNum.multSmpNum(reduceResult, new SMPNum(100));
				behaviour.gamePlayImpact.value = reduceResult.ToDoubleIfPossible();
				behaviour.gamePlayImpact.valueType = "DMGToPercent";
				behaviour.gamePlayImpact.dmgToPercent = bonusGoal.StringForSave();//bonusGoal.StringForSave();
				//console.log(behaviour.type+' setDMGToPercent '+bonusGoal.ToReadableAlphabetV2());
				//behaviour.gamePlayImpact.bean.valueType = { };
				//behaviour.gamePlayImpact.bean.valueType.text = reduceResult.ToReadableAlphabetV2() + "%";
				//behaviour.gamePlayImpact.bean.valueType.value = "DirectDisplayValue";
			} else if (isDefineByDuration) {
				behaviour.gamePlayImpact.value = bonusGoal.ToIntValue();// bonusGoal.ToIntValue();
				behaviour.gamePlayImpact.valueType = "DurationInSeconds";
				//behaviour.gamePlayImpact.bean.valueType = { };
				//behaviour.gamePlayImpact.bean.valueType.text = bonusGoal.ToIntValue() + " seconds";
				//behaviour.gamePlayImpact.bean.valueType.value = "DurationDirect";
			} else {
				behaviour.gamePlayImpact.value = bonusGoal._power;//bonusGoal.StringForSave();
				behaviour.gamePlayImpact.valueType = "DirectValue";
				//behaviour.gamePlayImpact.bean.valueType = { };
				//behaviour.gamePlayImpact.bean.valueType.text = bonusGoal.ToReadableAlphabetV2() + " (" + directUnit + ")";
				//behaviour.gamePlayImpact.bean.valueType.value = "DirectDisplayValue";
			}

			//behaviour.testSMPBaseOnStartLv = smpGetBase.StringForSave();
		}
	}

	getTextItemImpactAmount(gamePlayImpact, impType, gameLevel, currentLevelStage) {

		let isEndWithPreLater = false;
		let isEndWithPreNum = false;
		let preText = "";
		let subText = "";
		if (gamePlayImpact.value > 0) {
			if (gamePlayImpact.valueType === "DMGToPercent") {
				//apply reduce duration from level start to end
				let dmg = SMPNum.fromPowerString(gamePlayImpact.dmgToPercent);
				let perc = this.itemBatchHelper.getPercentFromDMG(dmg, currentLevelStage);
				//console.log(impType+' getDMGToPercent '+dmg.ToReadableAlphabetV2());
				preText = perc.toFixed(2).toString() + "%";
				isEndWithPreLater = true;
			} else if (gamePlayImpact.valueType === "DurationInSeconds") {
				//apply reduce duration from level start to end
				let duration = this.itemBatchHelper.getImpactReduceDurationToEndLevel(gamePlayImpact.value, gameLevel.levelStart, gameLevel.levelEnd, currentLevelStage);
				preText = duration + " seconds";
				isEndWithPreLater = true;
			} else if (gamePlayImpact.valueType === "Percentage") {
				preText = gamePlayImpact.value + "%";
			} else if (gamePlayImpact.valueType === "DirectValue") {
				preText = SMPNum.fromPowerString(gamePlayImpact.value).ToReadableAlphabetV2();
			} else {
				let value = gamePlayImpact.value;
				if (gamePlayImpact.valueType === "Capacity" || this.isDefineByNumOfTap(impType)) {
					value = this.itemBatchHelper.getImpactReduceDurationToEndLevel(value, gameLevel.levelStart, gameLevel.levelEnd, currentLevelStage);
				}
				preText = value.toString();
			}

			if (isEndWithPreLater) {
				if (gamePlayImpact.periodType === "DurationInSeconds"){
					subText = " every " + gamePlayImpact.periodValue + " second";
				} else if (gamePlayImpact.periodType === "NumOfGotHits"){
					subText = " every " + gamePlayImpact.periodValue + " got hit";
				} else if (gamePlayImpact.periodType === "NumOfTaps"){
					subText = " every " + gamePlayImpact.periodValue + " tap";
				}
			} else {
				if (gamePlayImpact.periodType === "DurationInSeconds") {
					subText = " every " + gamePlayImpact.periodValue + " second";
				} else if (gamePlayImpact.periodType === "NumOfGotHits") {
					subText = " every " + gamePlayImpact.periodValue + " got hit";
				} else if (gamePlayImpact.periodType === "NumOfTaps") {
					subText = " every " + gamePlayImpact.periodValue + " tap";
				}
			}
		} else {
			if (isEndWithPreNum) {
				if (gamePlayImpact.periodType === "DurationInSeconds") {
					subText = gamePlayImpact.periodValue + " second";
				} else if (gamePlayImpact.periodType === "NumOfGotHits") {
					subText = gamePlayImpact.periodValue + " got hit";
				} else if (gamePlayImpact.periodType === "NumOfTaps") {
					subText = gamePlayImpact.periodValue + " tap";
				}
			} else {
				if (gamePlayImpact.periodType === "DurationInSeconds") {
					subText = gamePlayImpact.periodValue + " second";
				} else if (gamePlayImpact.periodType === "NumOfGotHits") {
					subText = gamePlayImpact.periodValue + " got hit";
				} else if (gamePlayImpact.periodType === "NumOfTaps") {
					subText = gamePlayImpact.periodValue + " tap";
				} else if (this.isReviveHero(impType) ||
					this.isReviveSupport(impType)) {
					subText = "resurrection from the death";
				}
			}
		}

		return preText + subText;
	}

	getBossDmgBalance(monsterLevel) {
		return this.gamePlayService.getBossDmgBalance(monsterLevel);
	}

	getDmgBalanceToKillEnemy(monsterLevel) {
		return this.gamePlayService.getDmgBalanceToKillEnemy(monsterLevel);
	}

	getDurationBalanceToKillEnemy() {
		return this.gamePlayService.getDurationBalanceToKillEnemy();
	}

	isDefineByDurationToKillEnemy(impType) {
		if (impType === "freeze-enemy"
			|| impType === "sheep-enemy"
			|| impType === "inc_size_hero") {
			return true;
		}
		return false;
	}

	isDefineByBossHp(impType) {
		if (impType === "damage_enemy") {
			return true;
		}
		return false;
	}

	isDefineByCoinDrop(impType) {
		if (impType === "inc-gold-drop"
			|| impType === "inc_gold_enemy_drop") {
			return true;
		}
		return false;
	}

	isDefineByBossDMG(impType) {
		if (impType === "inc_hp"
			|| impType === "fill_hp") {
			return true;
		}
		return false;
	}

	isDefineByDmgBalanceToKillEnemy(impType) {
		if (impType === "inc-speed-support"
			|| impType === "inc_dps"
			|| impType === "poison-enemy") {
			return true;
		}
		return false;
	}

	isDefineByDMGAndConvertToPercent(impType) {
		if (impType === "inc-speed-support") {
			return true;
		} else if (this.isPowerUpType(impType)) {
			return true;
		} else if (this.isDefineByNumOfTap(impType)) {
			return true;
		}
		return false;
	}

	isDefineByNumOfTap(impType) {
		if (impType === "inc_pet_active_time") {
			return true;
		}
		return false;
	}

	isPowerUpType(impType) {
		return impType === "inc_powerup_capacity";
	}

	isUseCapacityValueType(impType) {
		return this.isDefineByNumOfTap(impType) || this.isPowerUpType(impType);
	}

	isUseDPSValueType(impType) {
		if (impType === "inc_dps") {
			return true;
		}
		return false;
	}

	isDefineByLifeTimeLimit(impType) {
		if (impType === "holding_tap"
			|| impType === "auto_tap") {
			return true;
		}
		return false;
	}

	isReviveHero(impType) {
		return impType === "revive_hero";
	}

	isReviveSupport(impType) {
		return impType === "revive_support";
	}
}

module.exports = SMPItemImpactBatchHelperUtils;