<template>
    <v-card
            class="mt-2"
            elevation="0"
            outlined
    >
        <v-card-subtitle><strong>History Balance</strong></v-card-subtitle>
        <v-row>
            <v-col class="pl-7 pt-0">
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                            label="Hero level which below boss"
                            v-model="balHeroLevelBelowBoss"
                            @input="changedSlider"
                            type="number"
                            :min="0"
                            :max="10"
                    />
                </v-card>
            </v-col>

            <v-col class="pt-0">
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                            label="Support level which below boss"
                            v-model="balSupportLevelBelowBoss"
                            @input="changedSlider"
                            type="number"
                            :min="0"
                            :max="10"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: "SettingHeroSupportLevelHistory.vue",
        props: ["defaultInputSet"],
        data() {
            return {
                balHeroLevelBelowBoss: 5,
                balSupportLevelBelowBoss: 3,
            }
        },
        created() {
            this.balHeroLevelBelowBoss = this.defaultInputSet.balHeroLevelBelowBoss;
            this.balSupportLevelBelowBoss = this.defaultInputSet.balSupportLevelBelowBoss;
        },
        methods:{
            changedSlider() {
                this.emitState();
            },
            emitState(){
                const outPayload = {
                    balHeroLevelBelowBoss: this.balHeroLevelBelowBoss,
                    balSupportLevelBelowBoss: this.balSupportLevelBelowBoss
                };
                this.$emit("onUpdateSettingHeroSupportLevelHistory", outPayload);
            },
        },
    }
</script>

<style scoped>

</style>