<template>
    <v-card
        max-width="700"
        min-width="700"
        elevation="0"
    >
        <v-card class="pa-1 mb-5">
            <v-row class="pl-3 pt-2 pb-2"
            >
                <v-col cols="7">
                    <span class="subheading">
                          <v-avatar>
                              <img
                                      :src="Tools.renderLocalPicture('quest_icon/quest_icon_0013_boss.png')"
                              >
                          </v-avatar>
                          <v-badge
                                  color="#89CFF0"
                                  offset-x="45"
                                  offset-y="45"
                                  :content="bossElementType"
                          >
                          </v-badge>
                          {{ bossInfo }}

                          <v-avatar>
                          <img
                                  :src="getHeroPicture"
                          >
                          </v-avatar>
                          <v-badge
                                  :color="getHeroElementTextColor"
                                 offset-x="43"
                                 offset-y="43"
                                 :content="heroElementType"
                             >
                           </v-badge>
                           {{ heroPossibleLevel }}
                      </span>
                </v-col>

                <v-col cols="1">
                    <v-avatar
                            :color="balanceHeroGhost"
                            size="50"
                    >
                        <span class="white--text text-h6">{{ this.heroBaseLevel - this.ghostLevel }}</span>
                    </v-avatar>
                </v-col>
               <v-col class="pt-6">
                   1 Ghost will drop : {{ ghostDrop }}
               </v-col>

            </v-row>
            <v-card-text>
                <v-row
                    class="mb-4"
                    justify="space-between"
                >
                    <v-col class="text-left">
                      <span
                          class="text-h4 font-weight-light"

                      >HTKG:</span>
                        <span
                            class="text-h3 font-weight-light"
                            v-text="hitPerGhosts"
                        ></span>
                        <span class="subheading font-weight-light mr-1">TTKG ({{ timeToKillGhost }} )</span>
                    </v-col>

                </v-row>

                <v-slider
                    v-model="ghostLevel"
                    :color="color1"
                    track-color="grey"
                    always-dirty
                    label="Level Stage"
                    thumb-label
                    min="1"
                    :max="getMaxLevelInput"
                    @input="onGhostLevelInputed"
                >
                    <template v-slot:append>
                        <v-text-field
                                v-model="ghostLevel"
                                class=" mt-0 pt-0"
                                type="number"
                                min="1"
                                :max="getMaxLevelInput"
                        ></v-text-field>
                    </template>
                </v-slider>

                <v-slider
                    v-model="heroBaseLevel"
                    :color="colorBaseHero"
                    track-color="grey"
                    always-dirty
                    label="Level Hero"
                    thumb-label
                    min="1"
                    :max="getMaxLevelInput"
                    @input="onHeroLevelInputed"
                >
                    <template v-slot:append>
                        <v-text-field
                                v-model="heroBaseLevel"
                                class=" mt-0 pt-0"
                                type="number"
                                min="1"
                                :max="getMaxLevelInput"
                        ></v-text-field>
                    </template>
                </v-slider>

                <v-btn
                        class="mr-1 ml-0"
                        @click="oneShot()"
                >
                    <v-avatar>
                        <v-img
                                max-height="30"
                                max-width="30"
                                :src="Tools.renderLocalPicture('skills/_Purple/violet_098.png')"
                                cover
                        />
                    </v-avatar>
                    Best hero level
                </v-btn>

                <v-btn
                        class="ml-1"
                        @click="doFindBestHero()"
                >
                    <v-avatar>
                        <v-img
                                max-height="30"
                                max-width="30"
                                :src="Tools.renderLocalPicture('skills/_Green/green_015.png')"
                                cover
                        />
                    </v-avatar>
                    Best hero select
                </v-btn>

<!--                Don't use capture feature which not use full with Kpi   -->
<!--                <v-btn-->
<!--                        class="ml-10"-->
<!--                        @click="doCapture()"-->
<!--                >-->
<!--                    <v-avatar>-->
<!--                        <v-img-->
<!--                                max-height="30"-->
<!--                                max-width="30"-->
<!--                                :src="Tools.renderLocalPicture('skills/_Green/green_019.png')"-->
<!--                                cover-->
<!--                        />-->
<!--                    </v-avatar>-->
<!--                    Capture-->
<!--                </v-btn>-->
<!--                <v-btn-->
<!--                        class="ml-2"-->
<!--                        @click="doClearCaptures()"-->
<!--                >-->
<!--                    Clears-->
<!--                </v-btn>-->

            </v-card-text>

            <SmpDialog :should-show="shouldShowGoldConfig"
                       @close="shouldShowGoldConfig = false">
                <PanelGoldConfig
                    v-if="shouldShowGoldConfig"
                    :defaultInputSet="defaultInputSet"
                    :payload="payload"
                    @onUpdateSettingGold="onUpdateSettingGold"
                />
            </SmpDialog>

            <SmpDialog
                :shouldShow="shouldShowDiamondConfig"
                @close="shouldShowDiamondConfig = false"
            >
                <PanelDiamondConfig
                    v-if="shouldShowDiamondConfig"
                    :defaultInputSet="defaultInputSet"
                    :payload="payload"
                    @onUpdateSettingDiamond="onUpdateSettingDiamond"
                />

            </SmpDialog>

            <SmpDialog :should-show="shouldShowSkillConfig"
                       @close="shouldShowSkillConfig = false">
                <PanelSkillConfig
                    v-if="shouldShowSkillConfig"
                    :defaultInputSet="defaultInputSet"
                    :payload="payload"
                    @onUpdateSettingSkill="onUpdateSettingSkill"
                />
            </SmpDialog>

            <SmpDialog
                    :shouldShow="shouldShowSyncConfig"
                    :pIgnoreClose="true"
            >
                <SyncConfigPopup
                        v-if="shouldShowSyncConfig"
                        :pConfig="modelInput"
                        @onPulled="this.onSyncConfigPulled"
                        @close="shouldShowSyncConfig=false"
                />
            </SmpDialog>

            <SmpDialog
                    :shouldShow="shouldShowMarketRequireConfig"
                    @close="shouldShowMarketRequireConfig = false"
            >
                <PanelMarketItemRequireConfig
                        v-if="shouldShowMarketRequireConfig"
                        :defaultInputSet="defaultInputSet"
                        :payload="payload"
                        @onUpdateSettingMarketRequire="onUpdateSettingMarketRequire"
                />

            </SmpDialog>

            <SmpDialog
                    :shouldShow="shouldShowSimulatorMarketRequire"
                    @close="shouldShowSimulatorMarketRequire = false"
            >
                <PanelSimulatorMarketItemRequireByLevel
                        v-if="shouldShowSimulatorMarketRequire"
                        :payload="payload"
                        @close="shouldShowSimulatorMarketRequire = false"
                        @simulatorLevel="onSimulatorLevel"
                />

            </SmpDialog>
        </v-card>



        <v-card elevation="0">
            <v-btn
                class="ma-2"
                @click="shouldShowGoldConfig = true"
            >
                <v-avatar>
                    <v-img
                        max-height="30"
                        max-width="30"
                        :src="Tools.renderLocalPicture('ui/coins.png')"
                        cover
                    />
                </v-avatar>
                Gold Setting
            </v-btn>
            <v-btn
                class="ma-2"
                @click="shouldShowDiamondConfig = true"
            >
                <v-avatar>
                    <v-img
                        max-height="30"
                        max-width="30"
                        :src="Tools.renderLocalPicture('ui/diamond.png')"
                        cover
                    />
                </v-avatar>
                Diamond Setting
            </v-btn>
            <v-btn
                class="ma-2"
                @click="shouldShowSkillConfig = true"
            >
                <v-avatar>
                    <v-img
                        max-height="30"
                        max-width="30"
                        :src="Tools.renderLocalPicture('skills/_Green/green_140.png')"
                        cover
                    />
                </v-avatar>
                Skill setting
            </v-btn>
            <v-btn
                    class="ma-2"
                    @click="shouldShowSyncConfig = true"
            >
                Sync config
            </v-btn>
            <v-btn
                    class="ma-2"
                    @click="shouldShowMarketRequireConfig = true"
            >
                <v-avatar>
                    <v-img
                            max-height="30"
                            max-width="30"
                            :src="Tools.renderLocalPicture('ui/market.png')"
                            cover
                    />
                </v-avatar>
                Market item require
            </v-btn>
            <v-btn
                    class="ma-2"
                    @click="shouldShowSimulatorMarketRequire = true"
            >
                Simulator Market require
            </v-btn>
        </v-card>

        <v-overlay :value="isLoading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-card>
</template>

<script>
import Tools from "@/utils/Tools";
import SMPNum from "@/SMPNum";
import SmpDialog from "@/item-editor/modules/SmpDialog.vue";
import PanelGoldConfig from "@/kpi-editor/modules/components/PanelGoldConfig.vue";
import PanelDiamondConfig from "@/kpi-editor/modules/components/PanelDiamondConfig.vue";
import PanelSkillConfig from "@/kpi-editor/modules/components/PanelSkillConfig.vue";
import PanelMarketItemRequireConfig from "@/kpi-editor/modules/components/PanelMarketItemRequireConfig.vue";
import PanelSimulatorMarketItemRequireByLevel from "@/kpi-editor/modules/components/PanelSimulatorMarketItemRequireByLevel.vue";
import {cloneDeep} from "lodash";
import {GlobalEvents, EVENT_REFRESH_MARKET, EVENT_TO_UPDATE_BEST_HERO, EVENT_TO_START_SIMULATE_HERO_HISTORY, EVENT_TO_END_SIMULATE_HERO_HISTORY} from "@/item-editor/events/GlobalEvents";
import SyncConfigPopup from "./components/SyncConfigPopup";
import ElementTypeAndName from "../../constants/ElementTypeAndName";

const MAX_LEVEL = 10000;

export default {
    name: "GroupInputLevel",
    components: {
        PanelSkillConfig,
        PanelDiamondConfig,
        PanelGoldConfig,
        SmpDialog,
        PanelMarketItemRequireConfig,
        PanelSimulatorMarketItemRequireByLevel,
        SyncConfigPopup,
    },
    props: {
        payload: {type:Object, default: null, required: true},
        defaultInputSet: {type:Object, default: null, required: true},
        pService: {type:Object, default: null, required: true}
    },
    data() {
        return {
            Tools: Tools,
            timerUpdateInput: null,
            shouldShowGoldConfig: false,
            shouldShowDiamondConfig: false,
            shouldShowSkillConfig: false,
            shouldShowMarketRequireConfig: false,
            shouldShowSimulatorMarketRequire: false,
            shouldShowSyncConfig: false,

            ghostLevel: 1,
            heroBaseLevel: 1,

            modelInput: null,

            heroHistory: [],
            isLoading: false,
        }
    },
    computed: {
        ghostDrop() {
            const {kpiGoldDropCurrentLevel} = this.payload;
            if (kpiGoldDropCurrentLevel) {
                return kpiGoldDropCurrentLevel.ToReadableAlphabetV2();
            } else {
                return 0;
            }
        },
        hitPerGhosts() {
            const {kpiHitsPerGhost} = this.payload;
            return kpiHitsPerGhost;
        },
        timeToKillGhost() {
            const {kpiTimeToKillGhost} = this.payload;
            if (kpiTimeToKillGhost) {
                return SMPNum.ToReadableTimeValue(kpiTimeToKillGhost);
            } else {
                return 0;
            }
        },
        heroPossibleLevel(){
            const {kpiHeroLevel} = this.payload;
            return kpiHeroLevel;
        },


        color1() {
            if (this.ghostLevel < 100) return 'indigo';
            if (this.ghostLevel < 125) return 'teal';
            if (this.ghostLevel < 140) return 'green';
            if (this.ghostLevel < 175) return 'orange';
            return 'red'
        },
        colorBaseHero() {
            if (this.heroBaseLevel < 100) return 'indigo';
            if (this.heroBaseLevel < 125) return 'teal';
            if (this.heroBaseLevel < 140) return 'green';
            if (this.heroBaseLevel < 175) return 'orange';
            return 'red'
        },
        balanceHeroGhost() {
            if (this.ghostLevel < this.heroBaseLevel) return 'green'
            return 'red'
        },

        bossInfo(){
          return this.ghostLevel;
        },

        heroId(){
            const {teamBattleInfo} = this.payload;
            let id = 1;
            if(teamBattleInfo){
                id = teamBattleInfo.heroData.m_iID;
            }
            return id;
        },

        getHeroPicture(){
            return Tools.renderHeroPicture(this.heroId);
        },

        getMaxLevelInput(){
            return MAX_LEVEL;
        },

        bossElementType(){
            const {bossInfo} = this.payload;
            if(bossInfo){
                return ElementTypeAndName[bossInfo.elementType];
            } else {
                return 'NO TYPE';
            }

        },
        getTeamBattleInfo(){
            const {teamBattleInfo} = this.payload;
            return teamBattleInfo;
        },
        heroElementType(){
            if(this.getTeamBattleInfo){
                return ElementTypeAndName[this.getTeamBattleInfo.heroData.elementType];
            } else {
                return 'NO TYPE';
            }
        },
        getHeroElementTextColor(){
            const {heroDmgSessionGainBoss} = this.payload;
            if(!heroDmgSessionGainBoss){
                return '#89CFF0';
            }
            else {
                if(heroDmgSessionGainBoss.isGain){
                    return 'green';
                } else {
                    return 'red';
                }
            }

        }
    },
    methods: {
        onUpdateInput() {

            if (this.timerUpdateInput) {
                clearTimeout(this.timerUpdateInput);
            }
            this.timerUpdateInput = setTimeout(() => {
                this.modelInput.gameLevel = this.ghostLevel;
                this.modelInput.heroLevel = this.heroBaseLevel;
                this.modelInput.heroHistory = this.heroHistory;

                this.$emit("onUpdateInput", this.modelInput);
            }, 200);
        },
        //events
        increment() {
            this.ghostLevel++;
            this.onGhostLevelInputed();
        },
        decrement() {
            if (this.ghostLevel > 1) {
                this.ghostLevel--;
                this.onGhostLevelInputed();
            }
        },
        onGhostLevelInputed() {
            if (this.ghostLevel < 1) {
                this.ghostLevel = 1;
            } else if (this.ghostLevel > MAX_LEVEL) {
                this.ghostLevel = MAX_LEVEL;
            }

           this.onUpdateInput();
        },
        onSimulatorLevel(level){
            this.ghostLevel = level;
            this.oneShotFromSimulator();
            setTimeout(()=> this.$emit("onUpdateInput", this.modelInput), 100);
            setTimeout(()=> this.oneShotFromSimulator(), 110);
            setTimeout(()=> this.$emit("onUpdateInput", this.modelInput), 120);
            setTimeout(()=> this.doFindBestHero(), 130);
            setTimeout(() => GlobalEvents.$emit(EVENT_REFRESH_MARKET), 140);
        },

        oneShotFromSimulator(){
            // const {kpiCumulatedGoldWon, kpiCumulatedGoldSupportSpent} = this.payload;
            // let availableGold = SMPNum.fromNum(0);
            // if (SMPNum.greaterThan(kpiCumulatedGoldWon, kpiCumulatedGoldSupportSpent)){
            //     availableGold.setFromSMPNum(SMPNum.minus(kpiCumulatedGoldWon, kpiCumulatedGoldSupportSpent));
            // }
            // let temp = this.service.computeMaximumLevelAchievable(this.ghostLevel, availableGold);
            // if (temp > 0) {
            //     this.heroBaseLevel = temp;
            // } else {
            //     this.heroBaseLevel = 1;
            // }

            GlobalEvents.$emit(EVENT_TO_START_SIMULATE_HERO_HISTORY);
            this.isLoading = true;

            this.defineMaximumLevelEachHero();

            this.modelInput.gameLevel = this.ghostLevel;
            this.modelInput.heroLevel = this.heroBaseLevel;
            this.modelInput.heroHistory = this.heroHistory;

            GlobalEvents.$emit(EVENT_TO_END_SIMULATE_HERO_HISTORY);
            this.isLoading = false;
        },

        incrementBaseHero() {
            this.heroBaseLevel++;
            this.onHeroLevelInputed();
        },
        decrementBaseHero() {
            if (this.heroBaseLevel > 1) {
                this.heroBaseLevel--;
                this.onHeroLevelInputed();
            }
        },

        onHeroLevelInputed() {
            if (this.heroBaseLevel < 1) {
                this.heroBaseLevel = 1;
            } else if (this.heroBaseLevel > MAX_LEVEL) {
                this.heroBaseLevel = MAX_LEVEL;
            }
            this.onUpdateInput();
        },

        doFindBestHero(){
            GlobalEvents.$emit(EVENT_TO_UPDATE_BEST_HERO);
        },

        oneShot() {

            // const {kpiCumulatedGoldWon, kpiCumulatedGoldSupportSpent} = this.payload;
            // let availableGold = SMPNum.fromNum(0);
            // if (SMPNum.greaterThan(kpiCumulatedGoldWon, kpiCumulatedGoldSupportSpent)){
            //     availableGold.setFromSMPNum(SMPNum.minus(kpiCumulatedGoldWon, kpiCumulatedGoldSupportSpent));
            // }
            // let temp = this.service.computeMaximumLevelAchievable(this.ghostLevel, availableGold);
            // if (temp > 0) {
            //     this.heroBaseLevel = temp;
            // } else {
            //     this.heroBaseLevel = 1;
            // }
            this.defineMaximumLevelEachHero();

            this.onUpdateInput();
        },

        defineMaximumLevelEachHero(){
            const {kpiCumulatedGoldWon, kpiHeroList} = this.payload;
            let percentToHero = 100 - this.modelInput.ratioAllocatedToSupport;
            let percentToHeroLevel = 100 - this.modelInput.ratioAllocatedToHeroSkill;
            let goldAvailableForHeroes = this.service.defineGoldAvailableForHeroes(kpiCumulatedGoldWon, percentToHero);
            let goldAvailableForHeroLevel = this.service.defineGoldAvailableForHeroes(goldAvailableForHeroes, percentToHeroLevel);

            //define history of hero level
            let historyHeroLastLevel = this.service.computeHistoryHeroSpent(this.ghostLevel, this.modelInput.balHeroLevelBelowBoss);
            let goldLastStageSpent = historyHeroLastLevel.goldSpent;
            if(SMPNum.greaterThan(goldAvailableForHeroLevel, goldLastStageSpent)){
                goldAvailableForHeroLevel = SMPNum.minus(goldAvailableForHeroLevel, goldLastStageSpent);
            } else {
                goldAvailableForHeroLevel = new SMPNum(0);
            }

            historyHeroLastLevel.historyHeroLevel.forEach(hero => {
                let kpiHero = kpiHeroList.find(kh => kh.m_iID === hero.m_iID);
                kpiHero.possibleLevel = hero.possibleLevel;
            });

            let bestHero = kpiHeroList.find(hero => hero.m_iID === historyHeroLastLevel.historyHeroLevel[0].m_iID);
            let temp = this.service.computeMaximumLevelAchievable(this.ghostLevel, goldAvailableForHeroLevel, bestHero);
            temp = temp >= bestHero.levelUnlock ? temp : bestHero.levelUnlock;
            if (temp > 0) {
                this.heroBaseLevel = temp;
            } else {
                this.heroBaseLevel = 1;
            }
            bestHero.possibleLevel = this.heroBaseLevel;
            historyHeroLastLevel.historyHeroLevel[0].possibleLevel = this.heroBaseLevel;
            this.heroHistory = historyHeroLastLevel.historyHeroLevel;
        },

        onUpdateSettingGold(receivedPayload) {
            if (receivedPayload){
                this.modelInput.percentageGoldBonus = Number(receivedPayload.percentageGoldBonus);
                this.modelInput.ratioAllocatedToHeroSkill = Number(receivedPayload.ratioAllocatedToHeroSkill);
                this.modelInput.ratioAllocatedToSupportLevel = Number(receivedPayload.ratioAllocatedToSupportLevel);
                this.modelInput.ratioAllocatedToSupport = Number(receivedPayload.ratioAllocatedToSupport);
                this.modelInput.ratioSupportSkill = Number(receivedPayload.ratioAllocatedToSupportSkill);
                this.modelInput.isIncludeMiniQuestGold = receivedPayload.isIncludeMiniQuestGold;
                this.modelInput.isIncludeDailyQuestGold = receivedPayload.isIncludeDailyQuestGold;
                this.modelInput.percentIncludeMiniQuestGold = receivedPayload.percentIncludeMiniQuestGold;
                this.modelInput.percentIncludeDailyQuestGold = receivedPayload.percentIncludeDailyQuestGold;
                this.modelInput.isUseAllGoldForMarketPlace = receivedPayload.isUseAllGoldForMarketPlace;
                this.modelInput.balHeroLevelBelowBoss = receivedPayload.balHeroLevelBelowBoss;
                this.modelInput.balSupportLevelBelowBoss = receivedPayload.balSupportLevelBelowBoss;
                
                this.onUpdateInput();
            }
        },
        onUpdateSettingDiamond(receivedPayload) {
            this.modelInput.ratioAllocatedToPet = Number(receivedPayload.ratioAllocatedToPet);
            this.onUpdateInput();
        },
        onUpdateSettingSkill(receivedPayload) {

            this.modelInput.percentControlSupportGoldSkill = Number(receivedPayload.percentControlSupportGoldSkill);
            this.modelInput.percentControlPetGoldSkill = Number(receivedPayload.percentControlPetGoldSkill);
            this.modelInput.isUseSupportSkillGold = receivedPayload.isUseSupportSkillGold;
            this.modelInput.isUseSupportSkillDmg = receivedPayload.isUseSupportSkillDmg;
            this.modelInput.isUsePetSkillGold = receivedPayload.isUsePetSkillGold;
            this.modelInput.isUsePetSkillDmg = receivedPayload.isUsePetSkillDmg;
            this.modelInput.isUsingPet = receivedPayload.isUsingPet;

            this.modelInput.isUseHeroSkillDmg = receivedPayload.isUseHeroSkillDmg;
            this.modelInput.isUseHeroSkillGold = receivedPayload.isUseHeroSkillGold;
            this.modelInput.percentControlHeroDmgSkill = receivedPayload.percentControlHeroDmgSkill;
            this.modelInput.percentControlHeroGoldSkill = receivedPayload.percentControlHeroGoldSkill;
            this.modelInput.isUseThunderAttackSkill = receivedPayload.isUseThunderAttackSkill;
            this.modelInput.isUsePetAllianceSkill = receivedPayload.isUsePetAllianceSkill;

            this.onUpdateInput();
        },
        onUpdateSettingMarketRequire(receivedPayload) {
            this.modelInput.timeRequireForDmgItem = Number(receivedPayload.timeRequireForDmgItem);
            this.modelInput.timeRequireForHpItem = Number(receivedPayload.timeRequireForHpItem);
            this.onUpdateInput();
        },
        onSyncConfigPulled(receivedPayload){
            if(receivedPayload){
                //merge modelInput to use server config
                this.modelInput = Object.assign(this.modelInput, receivedPayload);
                this.modelInput.gameLevel = this.ghostLevel;
                this.modelInput.heroLevel = this.heroBaseLevel;
                this.modelInput.heroHistory = this.heroHistory;
                this.onUpdateInput();
            }
        },
        doCapture(){
            this.$emit("onCapture");
        },
        doClearCaptures(){
            if (confirm('Are you sure you want to clear all kpi captures?')){
                this.$emit("onClearCaptures");
            }
        },
    },
    created() {
        this.modelInput = cloneDeep(this.defaultInputSet);
        this.ghostLevel = this.modelInput.gameLevel;
        this.heroBaseLevel = this.modelInput.heroLevel;

        this.service = this.pService;
    }
}
</script>

<style scoped>

</style>